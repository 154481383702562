import React, { useEffect } from "react";
import {
  LockOutlined,
  UserOutlined,
  GoogleCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Space,
  Spin,
  Tabs,
  message,
  theme,
} from "antd";
import type { CSSProperties } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { redirect, useNavigate } from "react-router";
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope("profile");
googleAuthProvider.addScope("email");

export default () => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const centerStyle: CSSProperties = {
    backgroundColor: token.colorBgContainer,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("Just logged in");
        navigate("farm");
      } else {
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);
  if (loading) {
    return (
      <div style={centerStyle}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }
  return (
    <div style={centerStyle}>
      <Card style={{ width: 300 }} title="Prijavljivanje">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Prijavi se" key="1" />
          <Tabs.TabPane tab="Registruj se" key="2" />
        </Tabs>
        <Form>
          <Form.Item>
            <Input
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              prefix={<UserOutlined className={"prefixIcon"} />}
              placeholder={"email"}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              onChange={(e) => setPassword(e.target.value)}
              size="large"
              prefix={<LockOutlined className={"prefixIcon"} />}
              type="password"
              placeholder={"password"}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              block
              onClick={async () => {
                const user = await createUserWithEmailAndPassword(
                  auth,
                  email,
                  password
                );
                console.log(user);
              }}
            >
              Prijavi se
            </Button>
          </Form.Item>
        </Form>
        <Divider>ili</Divider>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              signInWithPopup(auth, googleAuthProvider)
                .then((result) => {
                  // This gives you a Google Access Token. You can use it to access the Google API.
                  const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                  const token = credential?.accessToken;
                  // The signed-in user info.
                  const user = result.user;
                  console.log(user);
                  // IdP data available using getAdditionalUserInfo(result)
                  // ...
                })
                .catch((error) => {
                  // Handle Errors here.
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // The email of the user's account used.
                  const email = error.customData.email;
                  // The AuthCredential type that was used.
                  const credential =
                    GoogleAuthProvider.credentialFromError(error);
                  // ...
                });
            }}
            block
            icon={<GoogleCircleFilled />}
            style={{ backgroundColor: "#db4437", border: "none" }}
          >
            Prijavi se sa Google
          </Button>
        </Space>
      </Card>
    </div>
  );
};
