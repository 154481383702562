import { GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
// import {
//   klindra,
//   randomNJ,
//   srpskaCrnja,
//   vojvodaStepa,
// } from "../data/map/coordinates";
import navArrowSvg from "../assets/nav_arrow.svg";
import { FieldModel } from "../modules/fields/FieldModel";
import { getFieldHeading } from "../modules/fields/utils";
import { createButton } from "./map/createButton";
const containerStyle = {
  width: "100%",
  height: "100%",
};
const parsePolygon = (polygon: string) => {
  return JSON.parse(polygon).map((p: number[]) => ({
    lat: p[1],
    lng: p[0],
  }));
};
const polygonOptions: google.maps.PolygonOptions = {
  fillColor: "yellow",
  fillOpacity: 0.1,
  strokeWeight: 1,
  strokeColor: "yellow",
};
export const MapComponent: React.FC<{
  fields?: FieldModel[];
  polygon?: string;
  editablePolygon?: boolean;
  autoCenter?: boolean;
}> = ({ autoCenter, fields, polygon, editablePolygon = false }) => {
  const [mapHeading, setMapHeading] = useState(0);
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [libraries] = useState<any>(["marker"]);
  const [myLocation, setMyLocation] = useState<{
    lat: number;
    lng: number;
    heading: number | null;
  }>();
  const [myMarker, setMyMarker] =
    useState<google.maps.marker.AdvancedMarkerElement>();
  const [myMarkerArrow, setMyMarkerArrow] = useState<HTMLImageElement>();

  const [centerMe, setCenterMe] = useState<boolean>(false);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCs7SLVAQ6RUgaUeTb4WcWDdX6swb7zbz8",
    libraries,
    version: "weekly",
    mapIds: ["b84d0b4186873aab"],
  });
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  let watchID: number;

  useEffect(() => {
    if ("geolocation" in navigator) {
      console.log("Geolocation is available in this browser.");
      watchID = navigator.geolocation.watchPosition(
        (position) => {
          setMyLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            heading: position.coords.heading,
          });
        },
        (error) => {
          console.error(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }

    // Cleanup function to stop watching when component unmounts
    return () => {
      navigator.geolocation.clearWatch(watchID);
    };
  }, []);
  useEffect(() => {
    if (map && !myMarker && myLocation) {
      const markerArrow = document.createElement("img");
      markerArrow.src = navArrowSvg;
      setMyMarkerArrow(markerArrow);
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: myLocation,
        map: map,
        content: markerArrow,
        title: "My Location",
      });
      const markerDef = new google.maps.marker.AdvancedMarkerElement({
        position: myLocation,
        map: map,
        title: "D",
      });
      setMyMarker(marker);
      // markerArrow.style.transform = `rotate(30deg)`;
      const mapHeading = map.getHeading() ?? 0;
      markerArrow.style.width = "100px";
      markerArrow.style.height = "100px";
      markerArrow.style.marginBottom = "-50px";
      markerArrow.style.scale = "1.5";
      markerArrow.style.transform = `rotate(${
        360 - (myLocation.heading ?? 0)
      }deg)`;
    }
    if (map && myMarker && myLocation && myMarkerArrow) {
      myMarker.position = myLocation;
      const mapHeading = map.getHeading() ?? 0;
      myMarkerArrow.style.transform = `rotate(${
        -mapHeading + (myLocation.heading ?? 0)
      }deg)`;
    }
  }, [myLocation, myMarker, map, mapHeading]);

  const onLoad = React.useCallback(function callback(_map: google.maps.Map) {
    setMap(_map);
    initControls(_map);
  }, []);
  const initControls = React.useCallback(
    function callback(map: google.maps.Map) {
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].clear();
      const findMeButton = createButton(() => {
        setCenterMe(!centerMe);
      }, "/icons/near_me.svg");

      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(findMeButton);
    } as any,
    []
  );

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      if (polygon) {
        const points = JSON.parse(polygon);
        points.forEach((point: number[]) => {
          bounds.extend({ lat: point[1], lng: point[0] });
        });
      }
      if (fields && fields.length > 0) {
        fields.forEach((field) => {
          if (field.borders) {
            JSON.parse(field.borders).forEach((point: number[]) => {
              bounds.extend({ lat: point[1], lng: point[0] });
            });
          }
        });
      }
      setCenter(bounds.getCenter().toJSON());
      map.fitBounds(bounds);
    }
  }, [polygon, autoCenter, fields, map]);

  const focusField = (field: FieldModel) => {
    if (map && field.borders) {
      const bounds = new window.google.maps.LatLngBounds();
      JSON.parse(field.borders).forEach((point: number[]) => {
        bounds.extend({ lat: point[1], lng: point[0] });
      });
      map.fitBounds(bounds);
      setMapHeading(getFieldHeading(field) ?? 0);
    }
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={{
        mapTypeId: "satellite",
        mapId: "b84d0b4186873aab",
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        heading: mapHeading,
        // zoomControl: false,
        // rotateControl: true,
      }}
      zoom={19}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        {fields?.map((field) => {
          if (!field.borders) return null;
          return (
            <Polygon
              path={parsePolygon(field.borders)}
              key={field.id}
              options={polygonOptions}
              onClick={() => focusField(field)}
            />
          );
        })}
        {polygon && (
          <Polygon
            path={parsePolygon(polygon)}
            editable={false}
            options={polygonOptions}
          />
        )}
        {/* <Polygon path={vojvodaStepa} />
        <Polygon path={srpskaCrnja} options={{ fillColor: "red" }} />
        <Polygon path={randomNJ} options={{ fillColor: "blue" }} />
        <Polygon path={klindra} options={{ fillColor: "silver" }} /> */}
      </>
    </GoogleMap>
  ) : null;
};

export default React.memo(MapComponent);
