import {
  DesktopOutlined,
  PieChartOutlined,
  MenuFoldOutlined,
  BuildOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Avatar, Layout, Menu, theme, Button } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { auth } from "../firebase";

const { Header, Content, Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,

  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  {
    label: auth.currentUser?.displayName ?? "",
    key: "user",
    icon: (
      <Avatar
        size={32}
        src={auth.currentUser?.photoURL}
        icon={auth.currentUser?.photoURL ? null : undefined}
      />
    ),
    itemIcon: <SettingOutlined />,
  },
  getItem("Gazdinstvo", "/farm", <BuildOutlined />),
  getItem("Parcele", "/farm/fields", <PieChartOutlined />),
  getItem("Sezone", "/farm/seasons", <DesktopOutlined />),
  // getItem("User", "sub1", <UserOutlined />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
  // getItem("Team", "sub2", <TeamOutlined />, [
  //   getItem("Team 1", "6"),
  //   getItem("Team 2", "8"),
  // ]),
  // getItem("Files", "9", <FileOutlined />),
];
type CollapseType = "open" | "mini" | "closed";
const AppLayout: React.FC<{
  children: React.ReactElement;
  headerActions?: ReactElement[];
}> = ({ children, headerActions }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [collapseStatus, setCollapseStatus] = useState<CollapseType>(
    (localStorage.getItem("collapseStatus") ?? "open") as CollapseType
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (collapseStatus === "closed") {
      setCollapsedWidth(0);
      setCollapsed(true);
    } else if (collapseStatus === "mini") {
      setCollapsedWidth(80);
      setCollapsed(true);
    } else {
      setCollapsedWidth(80);
      setCollapsed(false);
    }
  }, [collapseStatus]);
  const doCollapse = () => {
    if (collapseStatus === "open") {
      setCollapseStatus("mini");
      localStorage.setItem("collapseStatus", "mini");
    } else if (collapseStatus === "mini") {
      setCollapseStatus("closed");
      localStorage.setItem("collapseStatus", "closed");
    } else {
      setCollapseStatus("open");
      localStorage.setItem("collapseStatus", "open");
    }
  };
  const avatar = auth.currentUser?.photoURL;
  return (
    <Layout hasSider style={{ height: "100vh" }}>
      <Sider
        // trigger={null}
        collapsible
        width={256}
        reverseArrow
        breakpoint="lg"
        collapsedWidth={collapsedWidth}
        collapsed={collapsed}
        onCollapse={(value) => doCollapse()}
      >
        {/* <Layout style={{ padding: "16px" }}>
          <Avatar src={avatar} icon={avatar ? null : <UserOutlined />} />
          <Link> {auth.currentUser?.displayName}</Link>
        </Layout> */}

        <Menu
          theme="dark"
          defaultSelectedKeys={[window.location.pathname]}
          mode="inline"
          onSelect={(selectInfo) => {
            if (selectInfo.key === "user") {
              auth.signOut();
            }
            navigate(selectInfo.key);
          }}
          items={items}
        />
      </Sider>
      <Layout style={{}}>
        <Header
          style={{
            padding: 0,
            background: theme.useToken().token.colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => doCollapse()}
            style={{
              fontSize: "16px",
              marginRight: "16px",
              color: theme.useToken().token.colorPrimaryText,
              width: 60,
              height: 60,
            }}
          />
          <div
            style={{
              display: "inline-flex",
              width: "calc(100% - 83px)",
              marginTop: "-8px",
              flexDirection: "row",
              height: "60px",
            }}
          >
            {headerActions}
          </div>
        </Header>
        <Content style={{ margin: "8px 8px", overflow: "scroll" }}>
          {children}
        </Content>
        {/* <Footer style={{ textAlign: "center", height: "32px" }}>
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
