import React, { useEffect, useState } from "react";
import AppLayout from "../../components/AppLayout";
import MapComponent from "../../components/MapComponent";
import { FieldDB } from "../fields/FIeldDB";
import { FieldModel } from "../fields/FieldModel";

export const FarmPage: React.FC = () => {
  const [fieldsWithGps, setFieldsWithGps] = useState<FieldModel[]>([]);
  useEffect(() => {
    FieldDB.all().then((fields) => {
      console.log(fields.filter((field) => field.borders));
      if (fields) {
        setFieldsWithGps(fields.filter((field) => field.borders));
      }
    });
  }, []);
  return (
    <AppLayout>
      <div style={{ width: "100%", height: "400px", minHeight: "400px" }}>
        <MapComponent fields={fieldsWithGps} />
      </div>
    </AppLayout>
  );
};
