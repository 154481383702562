

export const createButton = (cb: () => void, img: string) => {
  const button = document.createElement("button");
  button.textContent = "";
  button.addEventListener("click", cb);

  const icon = document.createElement("img");
  icon.src = img;

  // button.style.backgroundImage = `url(${img})`;
  button.appendChild(icon);
  // icon should be centered
  icon.style.width = "24px";
  icon.style.height = "24px";
  button.style.borderRadius = "4px";



  button.style.width = "40px";
  button.style.height = "40px";
  button.style.border = "none";
  button.style.cursor = "pointer";
  button.style.padding = "8px";
  button.style.backgroundColor = "white";
  button.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px";
  button.style.margin = "10px";
  return button;
};