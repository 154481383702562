
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAuth, connectAuthEmulator, onAuthStateChanged, User } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAX4La8CQpcRN3yn2xWaEUO3J73gy15-jc",
  authDomain: "navigate-farm-app.web.app",
  projectId: "navigate-farm",
  storageBucket: "navigate-farm.appspot.com",
  messagingSenderId: "847106093820",
  appId: "1:847106093820:web:e80f7a8e2f108fd7d21d79",
  measurementId: "G-QSZXWPK0B6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
if (process.env.NODE_ENV !== "production") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(db, "localhost", 8084, {});
}

export const getCurrentUser: () => Promise<User | null> = async () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    }, reject);
  });
}