import React, { useState } from "react";
import {
  ExportOutlined,
  ImportOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, Form, Input, message, Modal } from "antd";
import { FieldModel } from "./FieldModel";
import { FieldDB } from "./FIeldDB";

export const FieldActions: React.FC<{
  selected: React.Key[];
  rows: FieldModel[];
  onAction: (action: string) => void;
}> = ({ selected, rows, onAction }) => {
  const [showImport, setShowImport] = useState(false);
  const [fieldsToImport, setFieldsToImport] = useState<FieldModel[]>();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const action = e.key;
    if (action === "export") {
      // Initiate download of selected fields as json without creadedAt and updatedAt
      let selectedFields: any = rows.filter((row) => selected.includes(row.id));
      selectedFields = selectedFields.map((field: any) => {
        const { createdAt, updatedAt, ...rest } = field;
        return rest;
      });

      const json = JSON.stringify(selectedFields, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `fields-export-${new Date().getTime()}.json`;
      a.click();
    } else if (action === "import") {
      setShowImport(true);
    } else if (action === "delete") {
      let selectedFields = rows.filter((row) => selected.includes(row.id));
      selectedFields.forEach(async (field) => {
        await FieldDB.delete(field);
      });
      onAction("delete");
    } else if (action === "showWithParentId") {
      onAction("showWithParentId");
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Izvezi",
      key: "export",
      disabled: selected.length === 0,
      icon: <ExportOutlined />,
    },
    {
      label: "Uvezi",
      key: "import",
      icon: <ImportOutlined />,
    },
    {
      label: "Obriši",
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
      disabled: selected.length === 0,
    },
    {
      label: "Prikaži usitnjene parcele",
      key: "showWithParentId",
      // icon: <DeleteOutlined />,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleFileChosen = (file: any) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        setFieldsToImport(JSON.parse(fileReader.result as string));
      } catch (e) {
        console.log(e);
      }
    };
    fileReader.readAsText(file);
  };
  const closeImport = () => {
    setShowImport(false);
    setFieldsToImport(undefined);
  };
  const handleImport = async () => {
    // should iterate over all fieldsToImport and to save it
    if (!fieldsToImport) return;

    for (let field of fieldsToImport) {
      await FieldDB.create(field);
    }
  };
  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <MoreOutlined />
        </Button>
      </Dropdown>
      <Modal
        title="Uvoz parcela"
        open={showImport}
        onClose={closeImport}
        onCancel={closeImport}
        okText="Uvezi"
        onOk={handleImport}
        okButtonProps={{ disabled: !fieldsToImport }}
      >
        <Form>
          <Form.Item label="Fajl" name="name">
            <Input
              type="file"
              accept="json"
              onChange={(e) => handleFileChosen(e.target.files?.[0])}
            />
          </Form.Item>
          <br />
          {!fieldsToImport && (
            <>Nema parcela za uvoz. Odaberite fajl za uvoz!</>
          )}
          {fieldsToImport && (
            <>
              Parcele za uvoz:
              <br />
              {fieldsToImport.map((field) => (
                <div key={field.id}>
                  <b>{field.name}</b>
                </div>
              ))}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
