import { ConfigProvider } from "antd";
import srRs from "antd/es/locale/sr_RS";
import React from "react";
import ReactDOM from "react-dom/client";
import { AppRoutes } from "./AppRoutes";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// const language = navigator.language || "en-US";
const routes = createRoutesFromElements(AppRoutes);

const router = createBrowserRouter(routes);
root.render(
  <React.StrictMode>
    <ConfigProvider locale={srRs}>
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// [
//   "personalizeStartScreen",
//   "howDidYouHearAboutYunoScreen",
//   "howOldAreYouScreen",
//   "rateGeneralKnowledgeScreen",
//   "improveGeneralKnowledgeGrouped",
//   "reminderScreen",
//   "journeyPreparingScreen",
//   "welcomeAboardScreen",
//   "noAIScreen",
//   "learnQuizRepeatScreen",
//   "reviewScreen",
//   "yunoMomentScreen",
//   "review2Screen",
//   "subscriptionScreens"
// ]

// [
//   "howDidYouHearAboutYunoScreen",
//   "howOldAreYouScreen",
//   "rateGeneralKnowledgeScreen",
//   "improveGeneralKnowledgeGrouped",
//   "reminderScreen",
//   "subscriptionScreens"
// ]

// "journeyPreparingScreen",
// "welcomeAboardScreen",

// [
//   "welcomeScreen",
//   "journeyGrowthScreen",
//   "authScreen",
//   "personalizeStartScreen",
//   "howDidYouHearAboutYunoScreen",
//   "howOldAreYouScreen",
//   "rateGeneralKnowledgeScreen",
//   "importantConfidientScreen",
//   "importantSocialScreen",
//   "importantDatesScreen",
//   "importantWorkScreen",
//   "importantBigIdeasScreen",
//   "importantFactsScreen",
//   "importantSchoolScreen",
//   "reminderScreen",
//   "journeyPreparingScreen",
//   "welcomeAboardScreen",
//   "noAIScreen",
//   "learnQuizRepeatScreen",
//   "reviewScreen",
//   "yunoMomentScreen",
//   "review2Screen",
//   "subscriptionScreens"
// ]

// [ "howDidYouHearAboutYunoScreen",   "howOldAreYouScreen",   "rateGeneralKnowledgeScreen",   "improveGeneralKnowledgeGrouped",   "reminderScreen",   "onboardingTopicRateChooser", "journeyPreparingScreen", "welcomeAboardScreen", "subscriptionScreens" ]
// [       "howDidYouHearAboutYunoScreen",   "howOldAreYouScreen",   "rateGeneralKnowledgeScreen",   "improveGeneralKnowledgeGrouped",   "reminderScreen", "journeyPreparingScreen", "welcomeAboardScreen",  "subscriptionScreens" ]
// "journeyPreparingScreen", "welcomeAboardScreen",
