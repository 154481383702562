import React, { useEffect, useState } from "react";
import { FieldDB } from "../fields/FIeldDB";
import { FieldModel } from "../fields/FieldModel";
import MapComponent from "../../components/MapComponent";

export const AppPage: React.FC = () => {
  const [fieldsWithGps, setFieldsWithGps] = useState<FieldModel[]>([]);
  useEffect(() => {
    FieldDB.all().then((fields) => {
      if (fields) {
        setFieldsWithGps(fields.filter((field) => field.borders));
      }
    });
  }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <MapComponent fields={fieldsWithGps} />
    </div>
  );
};
