import React, { useEffect } from "react";
import { Form, Input, Select, Tabs } from "antd";
import { FieldModel } from "./FieldModel";
import {
  CrudForm,
  FormComponentProps,
  ItemType,
} from "../../components/crud/CrudForm";
import { FieldDB } from "./FIeldDB";
import { katastarskeOpstine } from "../../data/opstine";
import { MapInput } from "../../components/crud/MapInput";
import { useLoaderData } from "react-router";

export const FieldForm: React.FC<FormComponentProps<FieldModel>> = (props) => {
  const [allFields, setAllFields] = React.useState<FieldModel[]>([]);
  const routeItem = useLoaderData() as ItemType | undefined;
  useEffect(() => {
    FieldDB.all().then((fields) => {
      setAllFields(fields.filter((f) => f.id !== routeItem?.id));
    });
  }, [routeItem]);
  return (
    <CrudForm
      resource="/farm/fields"
      name="Parcela"
      crudClass={FieldDB}
      {...props}
      titleKey="name"
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane forceRender tab="Osnovni podaci" key="1">
          <Form.Item label="Naziv" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Katastarska opština" name="ko_id">
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                ...katastarskeOpstine.map((ko) => ({
                  value: ko.id,
                  label: ko.name,
                })),
              ]}
            />
          </Form.Item>
          <Form.Item label="Parcela ukrupljavanja" name="parentId">
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search to Select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                ...allFields.map((ko) => ({
                  value: ko.id,
                  label: ko.name,
                })),
              ]}
            />
          </Form.Item>
          <Form.Item label="Broj parcele" name="rs_ko_id">
            <Input />
          </Form.Item>
          <Form.Item label="Površina(m2)" name="area">
            <Input />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane forceRender tab="Koordinate" key="2">
          <Form.Item label="Koordinate parcele" name="borders">
            <MapInput />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane forceRender tab="Kulture" key="3"></Tabs.TabPane>
        <Tabs.TabPane forceRender tab="Sezone" key="4"></Tabs.TabPane>
        <Tabs.TabPane forceRender tab="Zadaci" key="5"></Tabs.TabPane>
      </Tabs>
    </CrudForm>
  );
};
