export const katastarskeOpstine = [
  {
    id: 800937,
    name: "VOJVODA STEPA",
    o_id: 80250
  },
  {
    id: 800872,
    name: "RADOJEVO",
    o_id: 80250
  },
  {
    id: 800899,
    name: "SRPSKA CRNJA",
    o_id: 80250
  },
  {
    id: 800929,
    name: "ALEKSANDROVO",
    o_id: 80250
  }
];