import {
  Breadcrumb,
  Button,
  Card,
  Flex,
  Radio,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { FieldModel } from "./FieldModel";
import { FieldDB } from "./FIeldDB";
import { katastarskeOpstine } from "../../data/opstine";
import {
  EditOutlined,
  CompassOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { FieldForm } from "./FieldForm";
import { TableRowSelection } from "antd/es/table/interface";
import { FieldActions } from "./actions";
import MapComponent from "../../components/MapComponent";
import AppLayout from "../../components/AppLayout";
import { AreaUnit, sqMeterToUnit } from "./utils";
const FieldsTable: React.FC<{ updateTimestamp: number }> = ({
  updateTimestamp,
}) => {
  const [showWithParent, setShowWithParent] = useState(false);
  const [areaUnit, setAreaUnit] = useState<AreaUnit>("ha");
  const [data, setData] = useState<FieldModel[]>([]);
  const [viewMode, setViewMode] = useState<"map" | "table">("table");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const naviagate = useNavigate();
  const columns: TableColumnsType<FieldModel> = [
    {
      title: "Naziv",
      dataIndex: "name",
      filterSearch: true,
      sortDirections: ["descend", "ascend"],
      sortOrder: "ascend",
      onFilter: (value, record) => record.name.includes(value as string),
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "20%",
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => naviagate(`/farm/fields/${record.id}`)}
          >
            {value}
          </Button>
        );
      },
    },
    {
      title: "Opština",
      dataIndex: "ko_id",
      sorter: (a, b) => a.area - b.area,
      render: (value) => {
        return katastarskeOpstine.find((ko) => ko.id === value)?.name ?? value;
      },
    },
    {
      title: "Broj parcele",
      dataIndex: "rs_ko_id",
    },
    {
      title: "Površina",
      dataIndex: "area",
      onHeaderCell: () => ({
        onClick: () => {
          console.log("onHeaderCell");
          if (areaUnit === "ha") {
            setAreaUnit("kj");
          } else {
            setAreaUnit("ha");
          }
        },
      }),
      onCellClick: (record) => {
        if (areaUnit === "ha") {
          setAreaUnit("kj");
        } else {
          setAreaUnit("ha");
        }
      },
      sorter: (a, b) => a.area - b.area,
      render: (_, record) => {
        let sumArea = sqMeterToUnit(
          data
            .filter((f) => f.parentId === record.id)
            .map((f) => Number(f.area))
            .reduce((a, b) => Number(a) + Number(b), Number(record.area)),
          areaUnit
        ).toFixed(2);
        const area = sqMeterToUnit(record.area, areaUnit).toFixed(2);
        return (
          <>
            {showWithParent ? area : sumArea} {areaUnit}
          </>
        );
        // return <>{value} ha</>;
      },
    },
    {
      title: "Stanje",
      dataIndex: "status",
      render: (value, record) => {
        return (
          <>{record.borders && <CompassOutlined title="Granice njive" />}</>
        );
      },
    },
    {
      title: "Akdcije",
      dataIndex: "actions",
      render: (value, record) => {
        return (
          <>
            <Button
              type="default"
              icon={<EditOutlined />}
              size="small"
              onClick={() => naviagate(`/farm/fields/${record.id}/edit`)}
            />
          </>
        );
      },
    },
  ];

  const onChange: TableProps<FieldModel>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  useEffect(() => {
    FieldDB.all().then(setData);
  }, [updateTimestamp]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<FieldModel> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const selectedFields = data.filter((row) => selectedRowKeys.includes(row.id));
  const selectedArea = selectedFields
    .map((v) => {
      if (!showWithParent) {
        return data
          .filter((f) => f.parentId === v.id)
          .map((v2) => v2.area)
          .reduce((a, b) => Number(a) + Number(b), Number(v.area));
      } else return v.area;
    })
    .reduce((a, b) => Number(a) + Number(b), Number(0));
  return (
    <AppLayout
      headerActions={[
        <Breadcrumb
          key={"breadcrumb"}
          style={{ marginTop: "6px" }}
          items={[
            { title: "Gazdinstvo", href: "/farm" },
            { title: `Parcele - ${viewMode === "map" ? "Mapa " : "Tabela "}` },
          ]}
        />,

        <Radio.Group
          key={"viewMode"}
          value={viewMode}
          style={{ marginLeft: "8px" }}
          onChange={(e) => setViewMode(e.target.value)}
        >
          <Radio.Button value="table">
            <TableOutlined />
          </Radio.Button>
          <Radio.Button value="map">
            <CompassOutlined />
          </Radio.Button>
        </Radio.Group>,
        <div key="space" style={{ display: "flex", flexGrow: "1" }} />,
        <Button
          type="link"
          key={"area"}
          style={{ paddingRight: "8px", cursor: "pointer" }}
          onClick={() => setAreaUnit(areaUnit === "ha" ? "kj" : "ha")}
        >
          {sqMeterToUnit(selectedArea, areaUnit).toFixed(2)} {areaUnit}
        </Button>,
        ...(viewMode === "table"
          ? [
              <Button
                type="primary"
                key={"add"}
                style={{ marginRight: "8px" }}
                onClick={() => naviagate("/farm/fields/create")}
              >
                Dodaj parcelu
              </Button>,
            ]
          : []),

        ...(viewMode === "table"
          ? [
              <FieldActions
                key={"actions"}
                selected={selectedRowKeys}
                rows={data}
                onAction={(action) => {
                  console.log(action);
                  if (action === "delete") {
                    FieldDB.all().then(setData);
                  }
                  if (action === "showWithParentId") {
                    setShowWithParent(!showWithParent);
                  }
                }}
              />,
            ]
          : []),
      ]}
    >
      <Card styles={{ body: { padding: "0px" } }}>
        {viewMode === "table" && (
          <Table
            bordered
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data.filter((f) =>
              showWithParent ? true : !f.parentId
            )}
            rowSelection={rowSelection}
            onChange={onChange}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <FieldForm model={record} mode="view" variant="inline" />
              ),
              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
          />
        )}
        {viewMode === "map" && (
          <div style={{ height: "calc(100vh - 81px)" }}>
            <MapComponent fields={data} autoCenter={true} />
          </div>
        )}
      </Card>
    </AppLayout>
  );
};

export default FieldsTable;
