import { collection, getDocs, query, where } from "firebase/firestore";
import { ModelDB } from "../../db/ModelDB";
import { auth, db, getCurrentUser } from "../../firebase";
import { AreaUnit, FarmModel } from "./FarmModel";

class FarmDBClass extends ModelDB<FarmModel> {
  async collection() {
    return collection(db, "farms");
  }
  async getCurrentFarm(): Promise<FarmModel> {
    const user = await getCurrentUser();
    if (!user) {
      throw new Error("User not found");
    }
    let farmJson = localStorage.getItem(`currentFarm:${user?.uid}`);

    if (farmJson) {
      return JSON.parse(farmJson!);
    }

    let farm = await this.find(user.uid);
    if (!farm) {
      farm = await this.createInitialFarm();
    }
    await this.setCurrentFarm(farm);
    return farm;
  }
  async createInitialFarm() {
    const user = await getCurrentUser();
    const farm = await this.create({
      id: user!.uid,
      name: `${user?.displayName}' farm`,
      ownerId: user!.uid,
      createdId: user!.uid,
      area: 0,
      areaUnit: AreaUnit.HECTARE,
      country: "Serbia",
      createdAt: new Date(),
      updatedAt: new Date(),
      location: "",
      farmId: "",
    });
    this.setCurrentFarm(farm);
    return farm;
  }
  setCurrentFarm(farm: FarmModel) {
    localStorage.setItem(
      `currentFarm:${auth.currentUser?.uid}`,
      JSON.stringify(farm)
    );
  }
  async getOwnedFarms(): Promise<FarmModel[]> {
    const q = query(
      await this.collection(),
      where("ownerId", "==", auth.currentUser?.uid)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as FarmModel;
    });
  }

  async getCurrentFarmLabeledValue() {
    const farm = await this.getCurrentFarm();
    if (farm) {
      return { label: farm.name, value: farm.id };
    }
    return null;
  }
}

export const FarmDB = new FarmDBClass();

export async function farmLoader() {
  return FarmDB.getCurrentFarm();
}
