
export enum AreaUnit {
  ACRE = 'acre',
  HECTARE = 'hectare',
  LANAC = 'lanac',
  JUTRO = 'jutro',
  RALI = 'rali',
  DULUM = 'dulum'
}
export type FarmModel = {
  id: string;
  ownerId: string;
  createdId: string;
  name: string;
  farmId: string;
  country: string;
  area: number;
  location: string;
  areaUnit: AreaUnit;
  createdAt: Date;
  updatedAt: Date;

};