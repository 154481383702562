import React, { CSSProperties, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../firebase";
import { User } from "firebase/auth";
import { Spin, theme } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Protected: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { token } = theme.useToken();
  const [userFetched, setUserFetched] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  const centerStyle: CSSProperties = {
    backgroundColor: token.colorBgContainer,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setUserFetched(true);
    });
    return unsubscribe;
  }, []);
  if (!userFetched) {
    return (
      <div style={centerStyle}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }
  return user ? <>{children}</> : <Navigate to="/login" />;
};
