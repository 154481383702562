import {
  Breadcrumb,
  Button,
  Form,
  FormProps,
  Input,
  message,
  Modal,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { auth } from "../../firebase";
import { useForm } from "antd/es/form/Form";
import { ModelDB } from "../../db/ModelDB";
import { useLoaderData, useNavigate } from "react-router";
import AppLayout from "../AppLayout";

export type ItemType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
};
export type FormComponentProps<Item extends ItemType> = {
  mode: "create" | "update" | "view";
  variant?: "modal" | "page" | "inline";
  model?: Item;
  children?: React.ReactNode;
  showSubmit?: boolean;
  afterCreate?: (item: Item) => void;
  afterUpdate?: (item: Item) => void;
  modalOpen?: boolean;
  onModalClose?: () => void;
};

export type CrudFormProps<Item extends ItemType> = FormComponentProps<Item> & {
  crudClass: ModelDB<Item>;
  titleKey: keyof Item;
  resource: string;
  name: string;
};

export const CrudForm = <Item extends ItemType>({
  mode,
  model,
  children,
  showSubmit = true,
  crudClass,
  afterCreate,
  afterUpdate,
  modalOpen = false,
  onModalClose,
  name,
  titleKey,
  resource,
  variant = "page",
}: CrudFormProps<Item>): React.ReactElement => {
  const routeItem = useLoaderData() as Item | undefined;
  const navigate = useNavigate();
  const [form] = useForm<Item>();
  const [isDirty, setIsDirty] = React.useState(false);
  const _model = model ?? routeItem;
  const onFinishHandler: FormProps<Item>["onFinish"] = async (values) => {
    const uid = auth.currentUser?.uid;
    if (mode === "create" && !routeItem) {
      const createdModel = await crudClass.create({
        ...values,
        ownerId: uid!,
        createdId: uid!,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      message.success("Farm created!");
      if (afterCreate) {
        afterCreate(createdModel);
      }
    }
    if (mode === "update" && routeItem) {
      const updatedModel = await crudClass.update({
        ...values,
        updatedAt: new Date(),
      });
      message.success("Farm updated!");
    }
  };
  const onFinishFailed: FormProps<Item>["onFinishFailed"] = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const hideSubmitButtons = variant === "modal" || !showSubmit;
  const formComponent = (
    <Form
      form={form}
      labelCol={{ span: 5 }}
      layout="horizontal"
      disabled={mode === "view"}
      // variant={"filled"}
      wrapperCol={{ span: 19 }}
      initialValues={_model}
      onFinish={onFinishHandler}
      onFinishFailed={onFinishFailed}
      onValuesChange={() => setIsDirty(true)}
    >
      {children}
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>

      {!hideSubmitButtons && (
        // <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
        <Form.Item>
          {mode !== "view" && (
            <Button type="primary" htmlType="submit" disabled={!isDirty}>
              {mode === "create" && "Create"}
              {mode === "update" && "Save"}
            </Button>
          )}
          {mode === "view" && (
            <Button
              type="primary"
              disabled={false}
              onClick={() => {
                navigate(`${resource}/${_model?.id}/edit`);
              }}
            >
              Edit
            </Button>
          )}
        </Form.Item>
      )}
    </Form>
  );
  if (variant === "page") {
    let crumbTitle = `Nova ${name}`;
    if (mode === "update") {
      crumbTitle = `Izmena ${name}: ${_model?.[titleKey]}`;
    } else if (mode === "view") {
      crumbTitle = `Pregled ${name}: ${_model?.[titleKey]}`;
    }

    return (
      <AppLayout
        headerActions={[
          <Breadcrumb
            key={"breadcrumb"}
            items={[
              { title: "Gazdinstvo", href: "/farm" },
              { title: "Parcele", href: "/farm/fields" },
              { title: `${crumbTitle}` },
            ]}
          />,
          <div key={"spacing"} style={{ display: "flex", flexGrow: "1" }} />,
          ...(mode === "view"
            ? [
                <Button
                  key={"edit"}
                  type="dashed"
                  style={{ marginTop: "-6px" }}
                  disabled={false}
                  onClick={() => {
                    navigate(`${resource}/${_model?.id}/edit`);
                  }}
                >
                  Edit
                </Button>,
              ]
            : []),
          ...(mode !== "view"
            ? [
                <Button
                  key={"submit"}
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: "-6px" }}
                  disabled={!isDirty}
                  onClick={() => form.submit()}
                >
                  {mode === "create" && "Create"}
                  {mode === "update" && "Save"}
                </Button>,
              ]
            : []),
        ]}
      >
        <>{formComponent}</>
      </AppLayout>
    );
  }
  if (variant === "modal") {
    const modalTitle = mode === "create" ? `Nova ${name}` : `Izmena ${name}`;
    const modalOkText = mode === "create" ? "Dodaj" : "Izmeni";
    return (
      <Modal
        open={modalOpen}
        title={modalTitle}
        onClose={() => onModalClose?.()}
        okText={modalOkText}
        onCancel={() => onModalClose?.()}
        onOk={() => {
          form.submit();
          onModalClose?.();
        }}
      >
        {formComponent}
      </Modal>
    );
  } else {
    return formComponent;
  }
};
