import { Button, Form, Input, Select } from "antd";
import React from "react";
import { countryOptions } from "../countries/CountriesEnum";
import { FarmModel } from "./FarmModel";
import { FarmDB } from "./FarmDB";
import { CrudForm, FormComponentProps } from "../../components/crud/CrudForm";

export const FarmForm: React.FC<FormComponentProps<FarmModel>> = (props) => {
  return (
    <CrudForm<FarmModel>
      resource="/farm"
      titleKey="name"
      name="gazdinstvo"
      crudClass={FarmDB}
      {...props}
      afterCreate={(createdModel) => {
        FarmDB.setCurrentFarm(createdModel);
        if (props.afterCreate) {
          props.afterCreate(createdModel);
        }
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Unesite naziv gazdinstva!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        rules={[{ required: true, message: "Morate odabrati državu!" }]}
      >
        <Select
          showSearch
          options={countryOptions}
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
        />
      </Form.Item>
    </CrudForm>
  );
};
