import { db } from "../../firebase";
import { collection } from "firebase/firestore";
import { FieldModel } from "./FieldModel";
import { ModelDB } from "../../db/ModelDB";
import { FarmDB } from "../farm/FarmDB";
import { NonIndexRouteObject } from "react-router";

class FieldDBClass extends ModelDB<FieldModel> {
  async collection() {
    // console.log("collection", (await FarmDB.getCurrentFarm())?.id, "fields");

    return collection(
      db,
      "farms",
      (await FarmDB.getCurrentFarm())?.id!,
      "fields"
    );
  }
  // async
}

export const FieldDB = new FieldDBClass();

export const fieldLoader: NonIndexRouteObject["loader"] = async ({
  params,
}) => {
  const { id } = params;
  return FieldDB.find(id!);
};
