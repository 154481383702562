import {
  Button,
  Card,
  Modal,
  Space,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import React, { useEffect, useState } from "react";
import FieldsTable from "./FieldsTable";

export const FieldsPage: React.FC = () => {
  return <FieldsTable updateTimestamp={1} />;
};
