import React, { ChangeEventHandler } from "react";
import MapComponent from "../MapComponent";
import { Input, Tabs } from "antd";

export const MapInput: React.FC<{
  id?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement> | undefined;
}> = ({ value, onChange }) => {
  return (
    <Tabs style={{ width: "100%", height: "400px", minHeight: "400px" }}>
      <Tabs.TabPane
        forceRender
        tab="Mapa"
        key="1"
        style={{ width: "100%", height: "400px", minHeight: "400px" }}
      >
        <MapComponent autoCenter polygon={value} editablePolygon />
      </Tabs.TabPane>
      <Tabs.TabPane tab="JSON" key="2" forceRender>
        <Input.TextArea
          size="large"
          rows={4}
          cols={100}
          value={value}
          onChange={onChange}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};
